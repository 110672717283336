import { useMemo } from 'react'
import {
  getDurationFromTimeStrings,
  getLocalTimezone,
  getUTCDateTime,
  toMinutes,
} from 'lib/time'
import { addDays } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

interface Props {
  date?: Date
  startTime?: string
  endTime?: string
  lunchLength?: number
  timezone?: string
}

export function useShiftTime(props: Props) {
  const {
    date = new Date(),
    timezone = getLocalTimezone(),
    startTime,
    endTime,
    lunchLength,
  } = props

  const durationInMinutesMinusLunch = useMemo(() => {
    if (!startTime || !endTime) {
      return null
    }
    return (
      getDurationFromTimeStrings(date, startTime, endTime, timezone) -
      (lunchLength ?? 0)
    )
  }, [startTime, endTime])

  //
  const totalDurationInMinutes = useMemo(() => {
    if (!startTime || !endTime) {
      return null
    }
    return getDurationFromTimeStrings(date, startTime, endTime, timezone)
  }, [startTime, endTime])

  const spansDaylightSaving = useMemo(() => {
    if (!startTime || !endTime) {
      return false
    }
    const startMinutes = toMinutes(startTime)
    const endMinutes = toMinutes(endTime)
    const startsAt = utcToZonedTime(
      getUTCDateTime(date, startTime, timezone),
      timezone
    )
    const endsAt = utcToZonedTime(
      getUTCDateTime(
        endMinutes < startMinutes ? addDays(date, 1) : date,
        endTime,
        timezone
      ),
      timezone
    )
    return startsAt.getTimezoneOffset() !== endsAt.getTimezoneOffset()
  }, [date, startTime, endTime])

  return {
    startTime,
    endTime,
    lunchLength,
    durationInMinutesMinusLunch,
    totalDurationInMinutes,
    isOvernight: startTime && endTime && endTime < startTime,
    spansDaylightSaving,
  }
}
