import * as zod from 'zod'
import { selectNumericOptionSchema, selectOptionSchema } from './generic'
import { getDurationFromTimeStrings, getLocalTimezone } from 'lib/time'

export const scheduleSchema = zod
  .object({
    selectedDays: zod
      .array(zod.date(), { required_error: 'Please select at least one day' })
      .min(1, 'Please select at least one day'),
    startTime: zod.object(selectOptionSchema, {
      required_error: 'Please select a start time',
    }),
    timezone: zod.string().default(getLocalTimezone()),
    endTime: zod.object(selectOptionSchema, {
      required_error: 'Please select an end time',
    }),
    lunchLength: zod.object(selectNumericOptionSchema).optional(),
    isW2: zod.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.isW2 && data.startTime && data.endTime) {
        const durationInMinutesMinusLunch =
          getDurationFromTimeStrings(
            new Date(),
            data.startTime.value,
            data.endTime.value,
            data.timezone
          ) - (data.lunchLength?.value ?? 0)

        if (durationInMinutesMinusLunch > 300) {
          return data.lunchLength !== undefined && data.lunchLength.value >= 30
        }
      }
      return true
    },
    {
      message:
        'Please review your selection. Meal break is required for this shift.',
      path: ['lunchLength'],
    }
  )
  .refine((data) => data.startTime.value !== data.endTime.value, {
    message: 'Shift must be longer than 0 minutes.',
    path: ['endTime'], // path of error
  })

export type ScheduleForm = zod.infer<typeof scheduleSchema>
