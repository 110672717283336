import { useMemo } from 'react'

interface UseLunchLabelParams {
  isW2: boolean
  totalDurationInMinutes: number | null
}

export function useLunchLabel({
  isW2,
  totalDurationInMinutes,
}: UseLunchLabelParams) {
  const lunchLabel = useMemo(() => {
    if (!isW2 || totalDurationInMinutes === null) {
      return 'Does this shift include an unpaid break?'
    }

    if (totalDurationInMinutes <= 5 * 60) {
      return 'Does this shift include an unpaid break?'
    }

    if (totalDurationInMinutes <= 10 * 60) {
      return '1 MEAL BREAK REQUIRED - This shift requires AT LEAST one unpaid break of 30 minutes. You may choose to add an additional 30 minute break.'
    }

    return '2 MEAL BREAKS REQUIRED - This shift requires 2 unpaid breaks of 30 minutes each, for a total of 60 minutes.'
  }, [isW2, totalDurationInMinutes])

  return lunchLabel
}
